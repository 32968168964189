@use 'shared/styles/mq';
@use 'shared/styles/variables';

$border-color: variables.$black;

.root {
    table {
        width: 100%;
        table-layout: fixed;
        border: none;
        border-collapse: collapse;
        
        thead {
            th {
                text-transform: capitalize;
            }
        }

        tbody {
            tr:last-child {
                th, td {
                    border-bottom: none;
                }
            }
        }

        th {
            text-align: center;
        }

        th, td {
            height: 48px;
            border: 1px solid $border-color;
            vertical-align: middle;
            box-sizing: border-box;
            position: relative;

            @include mq.to(md) {
                height: 36px;
                font-size: 12px;
            }

            &:first-child {
                border-left: none;

                @include mq.to(md) {
                    width: 48px;
                }

                @include mq.from(md) {
                    width: 64px;
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}

.today {
    background-color: variables.$black;
    color: variables.$white;

    @include mq.to(md) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    @include mq.from(md) {
        border-radius: 8px;
        padding: 4px 8px;
    }
}

.event {
    position: absolute;
    border: none;
    border-radius: 0;
    padding: 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    background-color: variables.$primary-color;
    color: variables.$white;
}

.title,
.level {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include mq.to(md) {
        display: none;
    }
}

.title {
    font-size: 14px;
}

.level {
    font-size: 12px;
}
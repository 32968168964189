@use 'sass:color';

@use 'shared/styles/variables';

.root {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.header {
    display: flex;
    padding-top: 6px;
    overflow-x: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.day {
    flex: 1;
    display: flex;
    justify-content: center;

    button {
        --counter-bg-color: #{variables.$black};
        --counter-text-color: #{variables.$white};

        position: relative;
        border: none;
        border-radius: 8px;
        padding: 4px 8px;
        background-color: transparent;
        color: variables.$black;;
        text-align: center;
        cursor: pointer;

        span {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-flex;
            justify-content: center;
            background-color: var(--counter-bg-color);
            color: var(--counter-text-color);
            width: 12px;
            height: 12px;
            border-radius: 100%;
            font-size: 10px;
            transform: translate(50%, -50%);
        }

        &:hover {
            background-color: color.scale(variables.$black, $alpha: -90%);
        }
    }

    &.today button {
        --counter-bg-color: #{variables.$black};
        --counter-text-color: #{variables.$white};

        background-color: variables.$violet;
        color: variables.$white;
    }

    &.active button {
        --counter-bg-color: #{variables.$white};
        --counter-text-color: #{variables.$black};

        background-color: variables.$black;
        color: variables.$white;
    }
}

.content {
    position: relative;
}

.event {
    background-color: variables.$black;
    color: variables.$white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 4px;
    border: none;
    border-radius: 0;
}
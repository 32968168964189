@use 'sass:color';

@use 'shared/styles/variables';

.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    background-color: transparent;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: color.scale(variables.$black, $alpha: -90%);
    }
}
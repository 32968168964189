#payment-view {
    padding: 30px;
}

.error-state,
.success-state {
    min-width: 360px;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    img {
        width: 200px;
        height: 200px;
        margin-bottom: 2rem;
    }
}